import React from 'react';
import QuestionComponent from '../components/page-components/question-component/question-component';

const Home = () => {
  return (
    <div>
      <QuestionComponent></QuestionComponent>
    </div>
  );
};

export default Home;
